import { render, staticRenderFns } from "./ProductDetailInfoModule.vue?vue&type=template&id=4c37e585&scoped=true&"
import script from "./ProductDetailInfoModule.vue?vue&type=script&lang=js&"
export * from "./ProductDetailInfoModule.vue?vue&type=script&lang=js&"
import style0 from "./ProductDetailInfoModule.vue?vue&type=style&index=0&id=4c37e585&prod&scoped=true&lang=css&"
import style1 from "./ProductDetailInfoModule.vue?vue&type=style&index=1&id=4c37e585&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c37e585",
  null
  
)

export default component.exports